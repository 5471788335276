import React from "react";
import { BsSpotify } from "react-icons/bs";
import { PiSoundcloudLogoFill } from "react-icons/pi";
import { FaFacebook } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
import { SiApplemusic } from "react-icons/si";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { NavLink } from "react-router-dom";
import { PageTitle } from "./PageTitle";
import { IonRow } from "@ionic/react";

export const IconBar = (props) => {
  return (
    <div className="icon-bar">
      <PageTitle pageTitle={"FOLLOW ME"} />
      &nbsp;
      <IonRow className="icon-bar-row">
        {iconsMenu &&
          iconsMenu.map((item, index) => (
            <DarkTooltip key={index} title={item?.title}>
              <NavLink
                key={index}
                to={item?.link}
                className="nav-item"
                target="_blank"
                rel="noopener noreferrer"
              >
                <item.Icon className="icon tooltip" />
              </NavLink>
            </DarkTooltip>
          ))}
      </IonRow>
    </div>
  );
};

export const DarkTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} placement="top" arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
    fontSize: 15,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    color: "#b6b6b6",
    fontSize: 11,
  },
  [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]:
    {
      margin: "10px",
    },
}));

export const iconsMenu = [
  {
    title: "SOUNDCLOUD",
    Icon: PiSoundcloudLogoFill,
    link: "https://soundcloud.com/hardcorebuddhist",
  },
  {
    title: "SPOTIFY",
    Icon: BsSpotify,
    link: "https://open.spotify.com/artist/6UMbXpVgril0PEbolGb60U",
  },
  {
    title: "APPLE MUSIC",
    Icon: SiApplemusic,
    link: "https://music.apple.com/us/artist/hardcore-buddhist/1407432485",
  },
  {
    title: "FACEBOOK",
    Icon: FaFacebook,
    link: "https://www.facebook.com/hardcorebuddhist/",
  },
  {
    title: "INSTAGRAM",
    Icon: FaInstagramSquare,
    link: "https://www.instagram.com/hardcore_buddhist/",
  },
  {
    title: "YOUTUBE",
    Icon: FaYoutube,
    link: "https://www.youtube.com/channel/UC_uTUpstHg33jwr6OQtdalw",
  },
];
