import React from "react";
import { IonGrid, IonRow, IonCard } from "@ionic/react";
import { PageTitle } from "../Components";

export const FrameArrayRender = (props) => {
  const { data, pageTitle } = props;

  return (
    <IonGrid>
      <PageTitle pageTitle={pageTitle} />
      <IonRow className="inner-container">
        {data &&
          data.map((obj, idx) => (
            <IonCard key={idx} className="release-card transparent">
              <div className="frame-box">
                <iframe
                  title="bc-player"
                  style={{
                    border: 0,
                    width: "18rem",
                    height: "18rem",
                  }}
                  src={obj?.src}
                  seamless
                />
              </div>
              <div className="card-footer">
                <IonRow>{obj?.name}</IonRow>
                <IonRow>{obj?.artist}</IonRow>
                <IonRow>{obj?.country}</IonRow>
              </div>
            </IonCard>
          ))}
      </IonRow>
    </IonGrid>
  );
};
