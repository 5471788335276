import { IonRow, IonCol } from "@ionic/react";
import { PageTitle } from "../Components";
import { NavLink } from "react-router-dom";

export const Merch = (props) => {
  const { isMobile } = props;
  return (
    <div id="MerchPage" className="merch-page">
      <div className="merch-box">
        <IonRow>
          <IonCol size={isMobile ? "12" : "5"} className="flex-center">
            <iframe
              className="merch-frame"
              title="title"
              src="https://bandcamp.com/EmbeddedPlayer/album=2312353811/size=large/bgcol=333333/linkcol=0f91ff/minimal=true/transparent=true/"
              seamless
            />
          </IonCol>
          <IonCol size={isMobile ? "12" : "7"} className="flex-center-column">
            <PageTitle pageTitle={"SUCCULENT ALBUM CD PACK 432Hz"} />
            <img src="/assets/cd-1.png" alt="" />
            <p>
              Includes unlimited streaming of Succulent album via the free
              Bandcamp app, plus high-quality download in MP3, FLAC and more!
            </p>
            <NavLink
              to="https://hardcorebuddhist.bandcamp.com/album/hardcore-buddhist-succulent-432hz-cd-only"
              target="_blank"
              className="flashing-btn"
            >
              <div className="link-text-home">€15.00</div>
            </NavLink>
            &nbsp;
          </IonCol>
        </IonRow>
      </div>
    </div>
  );
};
