export const Loading = () => {
  return (
    <div>
      <div className="loading-container">
        <div className="spinner"></div>
        <p>Loading...</p>
      </div>
    </div>
  );
};
