import React from "react";
import { IonRow, IonCol, IonText } from "@ionic/react";
import { SoundcloudPlayer } from "./SoundcloudPlayer";

export const Footer = (props) => {
  const { isMobile, isIntroPage } = props;
  // const [scrolled, setScrolled] = useState(false);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     let scrollPosition = window.scrollY;
  //     if (scrollPosition > window.innerHeight) {
  //       setScrolled(true);
  //       setTimeout(() => {
  //         setScrolled(false);
  //       }, 3000);
  //     }
  //   };
  //   window.addEventListener("scroll", handleScroll, { passive: true });
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // });

  // if (scrolled) {
  //   return null;
  // }

  // const className = !scrolled ? "footer shadow-img" : "hide";

  if (isIntroPage) {
    return null;
  }

  return (
    <IonRow id="Footer" className="footer shadow-img">
      {!isMobile && (
        <IonCol className="copyright-text">
          <IonRow>
            <IonCol> &#9426; &nbsp; All rights reserved</IonCol>
          </IonRow>
        </IonCol>
      )}
      <SoundcloudPlayer isMobile={isMobile} />
      {!isMobile && (
        <IonCol className="footer-text">
          <IonText>Powered by Harcore Buddhist</IonText>
        </IonCol>
      )}
    </IonRow>
  );
};
