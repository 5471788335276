import { IconBar, SpinningLogo } from "../Components";
import { IonRow } from "@ionic/react";

export const InfoBottom = (props) => {
  const { isIntroPage } = props;

  const className = "logos-info-bottom";

  if (isIntroPage) {
    return null;
  }
  return (
    <div id="InfoBottom" className="info-bottom">
      <SpinningLogo className={className} />
      <IconBar />
      <div className="credits-div">
        <IonRow className="flex-center no-padding">
          <p style={{ fontFamily: "Helvetica", fontSize: "0.8rem" }}>
            Design and development
          </p>
        </IonRow>
        <IonRow className="flex-center no-padding">
          <a
            href="https://linktr.ee/igor_morozov"
            target="_blank"
            rel="noreferrer"
            style={{
              fontFamily: "Helvetica",
              fontSize: "0.8rem",
              color: "white",
            }}
          >
            Igor Morozov
          </a>
        </IonRow>
      </div>
    </div>
  );
};
