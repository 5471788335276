import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import { IonCol } from "@ionic/react";
import { NavLink } from "react-router-dom";
import { CiMenuFries } from "react-icons/ci";

export const NavMenu = (props) => {
  const { navItems } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <IonCol className="menu-col">
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <CiMenuFries className="nav-menu-icon" />
      </Button>
      <Menu
        className="menu-dropdown"
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <IonCol className="nav-item-container transparent-frame">
          <ul>
            {navItems.map((item, index) => (
              <NavLink
                key={index}
                className="nav-item-menu"
                to={["/", item].join("")}
                style={({ isActive }) => {
                  return isActive
                    ? {
                        color: "#3ad246",
                      }
                    : null;
                }}
              >
                <span onClick={handleClose}>{item.toUpperCase()}</span>
              </NavLink>
            ))}
          </ul>
        </IonCol>
      </Menu>
    </IonCol>
  );
};
