import { useState, useEffect } from "react";

export const useReleaseData = (accessToken) => {
  const [albums, setAlbums] = useState(null);

  const artistId = "6UMbXpVgril0PEbolGb60U"; // Replace ARTIST_ID with the ID of the artist you want to fetch

  useEffect(() => {
    const fetchAlbums = async () => {
      if (!accessToken) return;

      let allAlbums = [];
      let offset = 0;
      const limit = 50;
      let total = 0;

      try {
        let response = await fetch(
          `https://api.spotify.com/v1/artists/${artistId}/albums?limit=1&offset=0`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch albums count");
        }

        const countData = await response.json();
        total = countData.total;

        while (offset < total) {
          response = await fetch(
            `https://api.spotify.com/v1/artists/${artistId}/albums?limit=${limit}&offset=${offset}`,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );

          if (!response.ok) {
            throw new Error("Failed to fetch albums");
          }

          const data = await response.json();
          allAlbums = [...allAlbums, ...data.items];
          offset += limit;
        }

        setAlbums(allAlbums);
      } catch (error) {
        console.error("Error fetching albums:", error);
      }
    };

    fetchAlbums();
  }, [artistId, accessToken]);

  return albums;
};

export const useReleaseDetails = (releaseId, accessToken) => {
  const [release, setRelease] = useState(null);

  useEffect(() => {
    const fetchReleaseDetails = async () => {
      try {
        if (!accessToken) {
          // If access token is not available, return early or handle it gracefully
          console.error("Access token not available");
          return;
        }

        // Fetch release details using the id from the URL
        const response = await fetch(
          `https://api.spotify.com/v1/albums/${releaseId}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch release details");
        }

        const data = await response.json();
        setRelease(data);
      } catch (error) {
        console.error("Error fetching release details:", error);
      }
    };
    if (accessToken) {
      fetchReleaseDetails();
    }
  }, [accessToken, releaseId]); // Trigger the effect whenever the id changes

  return release;
};
