// import { useState, useEffect } from "react";
import { IonRow, IonCol } from "@ionic/react";
import { NavMenu } from "./NavMenu";
import { NavLink } from "react-router-dom";
// import navItems from "../data/navItems.json";
// import { useScreenOrientation } from "../helpers/functions";
// import { ProgressBar } from "./ProgressBar";

export const navItems = [
  "home",
  "discography",
  "mixes",
  "videos",
  "gigs",
  "merch",
  "about",
  "contact",
];

export const NavHeader = (props) => {
  const { isMobile, isIntroPage } = props;
  // const [scrolled, setScrolled] = useState(false);

  // const orientation = useScreenOrientation();
  // const landscape = orientation === "landscape-primary";

  // const colSize = isMobile ? "6" : "auto";

  // useEffect(() => {
  //   const handleScroll = () => {
  //     let scrollPosition = window.scrollY;
  //     if (scrollPosition > window.innerHeight) {
  //       setScrolled(true);
  //     } else {
  //       setScrolled(false);
  //     }
  //   };
  //   window.addEventListener("scroll", handleScroll, { passive: true });
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // });

  if (isIntroPage) {
    return null;
  }
  // if (scrolled) {
  //   return null;
  // }

  return (
    <div id="Header" className="header shadow-img ">
      {isMobile ? <CompactHeader /> : <CompleteHeader />}
      {/* {loading && <ProgressBar loading={loading} />} */}
    </div>
  );
};

const CompactHeader = () => {
  return (
    <IonRow className="flex-center">
      <IonCol size="8" className="title-col-compact">
        <NavLink className="title-text" to="/">
          Hardcore
          <br />
          Buddhist
        </NavLink>
      </IonCol>
      <NavMenu navItems={navItems} />
    </IonRow>
  );
};

const CompleteHeader = () => {
  return (
    <div>
      <IonCol className="nav-items-col">
        <ul>
          {navItems.map((item, index) => (
            <NavLink
              key={index}
              className="nav-item"
              to={["/", item].join("")}
              style={({ isActive }) => {
                return isActive
                  ? {
                      color: "#3ad246",
                    }
                  : null;
              }}
            >
              <span>{item.toUpperCase()}</span>
            </NavLink>
          ))}
        </ul>
      </IonCol>
      <IonCol className="title-col">
        <NavLink className="title-text" to="/">
          Hardcore Buddhist
        </NavLink>
      </IonCol>
    </div>
  );
};
