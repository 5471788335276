import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  RedditShareButton,
  RedditIcon,
  WhatsappShareButton,
  WhatsappIcon,
  TelegramShareButton,
  TelegramIcon,
  TumblrShareButton,
  TumblrIcon,
} from "react-share";
import { IonCol } from "@ionic/react";

const hashtag = "#hardcorebuddhist";

export const ShareComponent = (props) => {
  const { currentURL } = props;

  return (
    <IonCol className="share-component">
      <p>Share it</p>
      &nbsp; &nbsp;
      <div>
        <FacebookShareButton url={currentURL} hashtag={hashtag}>
          <FacebookIcon size={25} round />
        </FacebookShareButton>
        &nbsp; &nbsp;
        <TwitterShareButton url={currentURL} hashtag={hashtag}>
          <TwitterIcon size={25} round />
        </TwitterShareButton>
        &nbsp; &nbsp;
        <RedditShareButton url={currentURL} hashtag={hashtag}>
          <RedditIcon size={25} round />
        </RedditShareButton>
        &nbsp; &nbsp;
        <WhatsappShareButton url={currentURL} hashtag={hashtag}>
          <WhatsappIcon size={25} round />
        </WhatsappShareButton>
        &nbsp; &nbsp;
        <TelegramShareButton url={currentURL} hashtag={hashtag}>
          <TelegramIcon size={25} round />
        </TelegramShareButton>
        &nbsp; &nbsp;
        <TumblrShareButton url={currentURL} hashtag={hashtag}>
          <TumblrIcon size={25} round />
        </TumblrShareButton>
      </div>
    </IonCol>
  );
};
