import { useState, useEffect } from "react";
import { ImageArrayRender } from "../Components";
import { useReleaseData } from "../helpers/useReleaseData";

const pageTitle = ["ALBUMS", "EPs and SINGLES", "FEATURING ON"];

export const Music = (props) => {
  const { accessToken } = props;
  const releases = useReleaseData(accessToken);
  const [albums, setAlbums] = useState([]);
  const [singles, setSingles] = useState([]);
  const [appearsOn, setAppearsOn] = useState([]);

  useEffect(() => {
    if (releases) {
      const albumsData = releases.filter((r) => r?.album_group === "album");
      const singlesData = releases.filter((r) => r?.album_group === "single");
      const appearsOnData = releases.filter(
        (r) => r?.album_group === "appears_on"
      );
      setAlbums(albumsData);
      setSingles(singlesData);
      setAppearsOn(appearsOnData);
    }
  }, [releases]);

  return (
    <div id="MusicPage" className="music-page">
      <div className="music-container">
        <ImageArrayRender data={albums} pageTitle={pageTitle[0]} />
        <ImageArrayRender data={singles} pageTitle={pageTitle[1]} />
        <ImageArrayRender data={appearsOn} pageTitle={pageTitle[2]} />
      </div>
    </div>
  );
};
