import { IonRow } from "@ionic/react";
import { IonText } from "@ionic/react";

export const PageTitle = (props) => {
  const { pageTitle, onClick, className } = props;
  return (
    <IonRow className="page-title">
      <IonText onClick={onClick} className={className}>
        {pageTitle}
      </IonText>
    </IonRow>
  );
};
