import { useState, useEffect } from "react";
import {
  SpinningLogo,
  FrameArrayRender,
  ImageArrayRender,
} from "../Components";
import { useReleaseData } from "../helpers/useReleaseData";
import { NavLink } from "react-router-dom";
import { IonRow } from "@ionic/react";
import { API_URL } from "../config/constants";
import axios from "axios";

export const Home = (props) => {
  const { accessToken } = props;

  const [releaseData, setReleaseData] = useState([]);
  const [mixData, setMixData] = useState([]);

  const releases = useReleaseData(accessToken);

  const latestMix = mixData.slice(0, 3);

  const className = "logos-home";

  useEffect(() => {
    if (releases) {
      const albumsData = releases.filter((r) => r?.album_group === "album");
      setReleaseData(albumsData);
    }

    const fetchMixes = async () => {
      try {
        const mixes = await axios.get(`${API_URL}/mix`);
        setMixData(mixes?.data);
      } catch (e) {
        console.error("Error fetching mixes:", e.message);
      }
    };
    fetchMixes();
  }, [releases]);

  return (
    <div id="HomePage" className="home-page">
      <div className="home-image-div">
        <SpinningLogo className={className} />
      </div>
      <div className="music-page">
        <div className="music-container">
          <ImageArrayRender data={releaseData} pageTitle={"ALBUMS"} />
          <IonRow className="flex-center">
            <NavLink to="/discography" className="flashing-btn">
              <div className="link-text-home">COMPLETE DISCOGRAPHY</div>
            </NavLink>
          </IonRow>
          <FrameArrayRender data={latestMix} pageTitle={"LATEST MIX"} />
          <IonRow className="flex-center">
            <NavLink to="/mixes" className="flashing-btn">
              <div className="link-text-home">ALL MIXES</div>
            </NavLink>
          </IonRow>
        </div>
      </div>
    </div>
  );
};
