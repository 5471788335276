import { useState } from "react";
import axios from "axios";
import { PageTitle } from "./PageTitle";
import { validateEmail } from "../helpers/helpers";

export const ContactForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const [isValidEmail, setIsValidEmail] = useState(false);

  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState("");

  const handleEmailChange = (event) => {
    const { value } = event.target;
    setEmail(value);
    setIsValidEmail(validateEmail(value));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isValidEmail) {
      // alert("Please enter a valid email address.");
      setError("Please enter a valid email address.");
      setSubmitted(false);
      setEmail("");
      return;
    }
    try {
      await axios.post("https://formcarry.com/s/-xYfevi8i8D", {
        name: name,
        email: email,
        message: message,
      });
      setSubmitted(true);
    } catch (error) {
      setError(
        "An error occurred while submitting the form. Please try again later."
      );
      console.error("Error submitting form:", error);
    }
  };

  if (submitted) {
    return <p style={{ color: "white" }}>Thank you for contacting me!</p>;
  }

  return (
    <form onSubmit={handleSubmit} className="contact-form shadow-img">
      <PageTitle pageTitle={"CONTACT ME"} />
      {error && <p style={{ color: "white" }}>{error}</p>}
      <input
        id="name"
        type="text"
        value={name}
        onChange={(e) => setName(e.target.value)}
        required
        placeholder="Your name"
      />
      <input
        id="email"
        type="email"
        value={email}
        onChange={handleEmailChange}
        required
        placeholder="Your e-mail"
      />
      <textarea
        id="message"
        value={message}
        required
        onChange={(e) => setMessage(e.target.value)}
        placeholder="Message"
      />
      <button type="submit" className="subscribe-btn shadow-img">
        <div className="subscribe-btn-text">SUBMIT</div>
      </button>
    </form>
  );
};
