import { IonRow } from "@ionic/react";
import { IonCol } from "@ionic/react";
import { IonImg } from "@ionic/react";

export const SpinningLogo = (props) => {
  const { className } = props;

  return (
    <IonRow className="spinning-logo-row">
      <IonCol size="auto" className={className}>
        <IonImg className="logoHB" src="/assets/logo-hb.png" alt="logo-hb" />
        <IonImg
          src="/assets/mandala.png"
          className="mandala-logo"
          alt="mandala"
        />
      </IonCol>
    </IonRow>
  );
};
