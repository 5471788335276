import React from "react";
import { IonRow } from "@ionic/react";
import { PageTitle } from "../Components";
import { importAll } from "../helpers/helpers";

const images = importAll(
  require.context("/public/assets/bio", false, /\.(png|jpeg|jpg|svg)$/)
);

export const About = (props) => {
  return (
    <div id="AboutPage" className="about-page">
      <div className="about-main-img">
        {/* <img src="/assets/image03.jpeg" alt="" /> */}
      </div>
      <div className="about-container">
        <div className="image-2">
          <img
            src="/assets/image_3005.jpeg"
            alt=""
            className="shadow-no-border"
          />
        </div>
        <div className="about-box">
          <div className="about-text shadow-no-border">
            <PageTitle pageTitle={"BIO"} />
            <p>{text1}</p>
            <p>{text2}</p>
          </div>
        </div>
      </div>
      <ImageBox />
    </div>
  );
};

export const ImageBox = () => {
  return (
    <IonRow id="AllImageContainer" className="all-img-container">
      {images.map((image, idx) => (
        <div key={idx} className="img-box ">
          <img
            key={idx}
            src={image}
            alt=""
            className="shadow-img image3"
            style={{ border: "3px solid white" }}
          />
        </div>
      ))}
    </IonRow>
  );
};

const text1 =
  "It started in 2016 as a chillgressive project but then evolved into something more expansive and profound, breaking the boundaries between styles. At one point I realised that sticking to one particular style is quite boring for me and I decided not to limit myself and just follow the flow since creativity is first of all about the process. Today I would describe my project as multi-genre ones, that seamlessly blends chill-out mood with danceable progressive rhythms and psychedelic vibe, creating complete composition in a diverse range of genres, including psybient, chillgressive, psychill, progressive, breakbeat and techno.  It is an emotionally-charged journey filled with intricate sound design, dynamic energy flow and uplifting cosmic vibes. Each track paints a trippy and exciting story that combines sassy, romantic, psychedelic, and oriental influences, combining depth with lightness and playfulness with drama.";

const text2 =
  "Since 2017 released 3 albums, 10 EPs and numerous collaboration tracks and remixes with esteemed artists like Side Liner, Kick Bong, Dense, Zymosis and more. Music released primarily on Cosmicleaf Records but also on Vertigo Records, Mystic Sound Records and Sentimony Records.";
