import ScrollToTop from "react-scroll-to-top";

export const ScrollToTopBtn = () => {
  return (
    <ScrollToTop
      className="scroll-top"
      // style={{ backgroundColor: "#0e1111", width: "5rem" }}
      color="white"
    />
  );
};
