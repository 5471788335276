import { IonRow } from "@ionic/react";

export const PageNotFound = () => {
  return (
    <div className="page-not-found">
      <IonRow>
        <p>404 - Page Not Found</p>
      </IonRow>
      <IonRow>
        <p>The page you are looking for does not exist.</p>
      </IonRow>
    </div>
  );
};
