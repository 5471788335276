import React from "react";
import { IonGrid, IonRow, IonCard } from "@ionic/react";
import { PageTitle } from "../Components";
import { NavLink } from "react-router-dom";

export const ImageArrayRender = (props) => {
  const { data, pageTitle } = props;
  return (
    <IonGrid>
      <PageTitle pageTitle={pageTitle} />
      <IonRow className="inner-container">
        {data &&
          data.map((obj) => (
            <IonCard key={obj.id} className="release-card transparent">
              <NavLink
                to={`/discography/${obj?.id}`}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <div className="frame-box">
                  <img
                    alt="Release Cover"
                    src={obj?.images[1]?.url}
                    style={{
                      border: 0,
                      width: "18rem",
                      height: "18rem",
                    }}
                    seamless
                  />
                </div>
                <div className="card-footer">
                  <IonRow>{obj?.name}</IonRow>
                  <IonRow>
                    {obj?.artists.map((artist, index) => (
                      <div key={artist.id}>
                        {artist.name}
                        {/* Add comma and space if not the last artist */}
                        {index !== obj.artists.length - 1 && ", "}
                      </div>
                    ))}
                  </IonRow>
                </div>
              </NavLink>
            </IonCard>
          ))}
      </IonRow>
    </IonGrid>
  );
};
