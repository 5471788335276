import { useState, useEffect } from "react";
import { FrameArrayRender } from "../Components";
import { API_URL } from "../config/constants.js";
import axios from "axios";

export const Mix = (props) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchMixes = async () => {
      try {
        const mixes = await axios.get(`${API_URL}/mix`);
        setData(mixes.data);
      } catch (e) {
        console.error("Error fetching mixes:", e.message);
      }
    };

    fetchMixes();
  }, []);

  return (
    <div id="MusicPage" className="music-page">
      <div className="music-container">
        <FrameArrayRender data={data} pageTitle={"MIXES"} />
      </div>
    </div>
  );
};
