import { useReleaseDetails } from "../helpers/useReleaseData";
import { useParams, NavLink } from "react-router-dom";
import { IonRow, IonCol } from "@ionic/react";
import { ShareComponent } from "../Components";

export const ReleaseDetails = (props) => {
  const { id } = useParams(); // Get the release id from the URL parameters
  const { accessToken, currentURL } = props;

  const release = useReleaseDetails(id, accessToken);

  const embedUrl = `https://open.spotify.com/embed/album/${id}?utm_source=generator&theme=0`;
  return (
    <div
      id="DetailsPage"
      className="details-page"
      // style={{
      //   backgroundImage: `url(${release?.images[0]?.url})`,
      //   backgroundSize: "cover",
      //   backgroundAttachment: "fixed",
      //   backgroundPosition: "center",
      // }}
    >
      <IonCol className="details-frame-col">
        <IonRow>
          <iframe
            title={release?.name}
            src={embedUrl}
            frameBorder="0"
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            loading="lazy"
          ></iframe>
        </IonRow>
        <IonRow>
          <ShareComponent currentURL={currentURL} />
          <IonCol size="12" className="flex-center">
            <NavLink
              to="https://hardcorebuddhist.bandcamp.com/album/hardcore-buddhist-succulent-432hz-cd-only"
              target="_blank"
              className="flashing-btn"
            >
              <div className="link-text-home">BUY THIS MUSIC</div>
            </NavLink>
          </IonCol>
        </IonRow>
      </IonCol>
    </div>
  );
};
