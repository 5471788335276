import React, { useState, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import {
  Home,
  About,
  Intro,
  Music,
  Video,
  Gigs,
  Contact,
  Mix,
  Merch,
  PageNotFound,
  ReleaseDetails,
} from "./Pages";
import {
  NavHeader,
  Footer,
  ScrollToTopBtn,
  InfoBottom,
  SubscribeForm,
  Loading,
} from "./Components";
import { setupIonicReact } from "@ionic/react";
import "./Theme/general.css";

setupIonicReact();
const mobileSize = 1030;

function App() {
  const location = useLocation();
  const currentURL = window.location.href;
  const pathname = location?.pathname;
  const isIntroPage = pathname === "/";
  const [loading, setLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < mobileSize);
  const [accessToken, setAccessToken] = useState(null);

  useEffect(() => {
    const fetchAccessToken = async () => {
      try {
        const response = await fetch("https://accounts.spotify.com/api/token", {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization:
              "Basic " +
              btoa(
                "dfc997b934964497a7f7478ec70af2fc:ab6350b5a42d4fa1b3f36ad0201c4ccb"
              ),
          },
          body: "grant_type=client_credentials",
        });

        if (!response.ok) {
          throw new Error("Failed to fetch access token");
        }

        const data = await response.json();
        setAccessToken(data.access_token);
      } catch (error) {
        console.error("Error fetching access token:", error);
      }
    };

    fetchAccessToken();
  }, []);

  const handleLoading = () => {
    setLoading(false);
  };

  const handleResize = () => {
    setIsMobile(window.innerWidth < mobileSize);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    window.addEventListener("load", handleLoading);
    return () => {
      window.removeEventListener("load", handleLoading);
    };
  }, []);

  useEffect(() => {
    // Scroll to the top of the page when the route changes
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div>
      <NavHeader
        isMobile={isMobile}
        isIntroPage={isIntroPage}
        loading={loading}
      />
      {!isIntroPage && <ScrollToTopBtn />}

      {loading ? (
        <Loading />
      ) : (
        <Routes>
          <Route path="/" element={<Intro isMobile={isMobile} />} />
          <Route
            path="/home"
            element={<Home isMobile={isMobile} accessToken={accessToken} />}
          />
          <Route
            path="/discography"
            element={
              <Music
                isMobile={isMobile}
                pathname={location?.pathname}
                loading={loading}
                accessToken={accessToken}
              />
            }
          />
          <Route path="/mixes" element={<Mix />} />
          <Route path="/videos" element={<Video />} />
          <Route path="/gigs" element={<Gigs />} />
          <Route path="/merch" element={<Merch isMobile={isMobile} />} />
          <Route path="/about" element={<About isMobile={isMobile} />} />
          <Route path="/contact" element={<Contact isMobile={isMobile} />} />
          <Route path="*" element={<PageNotFound />} />
          <Route
            path="/discography/:id"
            element={
              <ReleaseDetails
                accessToken={accessToken}
                isMobile={isMobile}
                currentURL={currentURL}
              />
            }
          />
        </Routes>
      )}
      <SubscribeForm isIntroPage={isIntroPage} />
      <InfoBottom isMobile={isMobile} isIntroPage={isIntroPage} />
      <Footer isMobile={isMobile} isIntroPage={isIntroPage} />
    </div>
  );
}

export default App;
