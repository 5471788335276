import { useState, useEffect } from "react";
import { IonRow, IonCol } from "@ionic/react";
import { sortByDate } from "../helpers/helpers";
import { PageTitle } from "../Components";
import { API_URL } from "../config/constants";
import { FaExternalLinkAlt } from "react-icons/fa";

import axios from "axios";

export const Gigs = () => {
  const [data, setData] = useState([]);
  const [isPastEventsCollapsed, setIsPastEventsCollapsed] = useState(true);

  const sortedGigs = sortByDate(data);
  const upcomingGigs = sortedGigs.filter(
    (gig) => new Date(gig.date) >= new Date()
  );
  const pastGigs = sortedGigs.filter((gig) => new Date(gig.date) < new Date());

  const festivals = pastGigs.filter((gig) => gig.type === "festival");
  const parties = pastGigs.filter((gig) => gig.type === "party");
  const radioShows = pastGigs.filter((gig) => gig.type === "radio");

  useEffect(() => {
    const fetchGigs = async () => {
      try {
        const gigs = await axios.get(`${API_URL}/gig`);
        setData(gigs.data);
      } catch (e) {
        console.error("Error fetching gigs:", e.message);
      }
    };
    fetchGigs();
  }, []);

  const togglePastEventsVisibility = () => {
    setIsPastEventsCollapsed((prevState) => !prevState);
  };

  return (
    <div id="GigsPage" className="gigs-page">
      <div className="gigs-box ">
        <EventRow pageTitle={"UPCOMING EVENTS"} data={upcomingGigs} />
        &nbsp;
        <PageTitle
          pageTitle={"PAST EVENTS"}
          onClick={togglePastEventsVisibility}
          className="page-title-clickable"
        />
        {!isPastEventsCollapsed && (
          <>
            <EventRow pageTitle={"FESTIVALS"} data={festivals} />
            <EventRow pageTitle={"PARTIES"} data={parties} />
            <EventRow pageTitle={"RADIO SHOWS"} data={radioShows} />
          </>
        )}
      </div>
    </div>
  );
};

const EventRow = (props) => {
  const { pageTitle, data } = props;

  return (
    <div>
      <PageTitle pageTitle={pageTitle} />
      {data.map((gig, idx) => (
        <IonRow key={idx} className="gig-row transparent-frame">
          <IonCol size="3">
            {new Date(gig.date).toLocaleDateString("en-GB")}
          </IonCol>
          <IonCol size="5.5">{gig.name}</IonCol>
          <IonCol size="1">
            <a
              href={gig.link}
              className="gig-link"
              target="_blank"
              rel="noreferrer"
            >
              {gig.link && <FaExternalLinkAlt />}
            </a>
          </IonCol>
          <IonCol size="2.5">{gig.country}</IonCol>
        </IonRow>
      ))}
    </div>
  );
};
