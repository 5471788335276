import React from "react";
import { IonRow, IonText } from "@ionic/react";
import { NavLink } from "react-router-dom";
import { SpinningLogo } from "../Components/SpinningLogo";

export const Intro = () => {
  const className = "logos-intro";
  return (
    <div id="IntroPage" className="intro-page">
      <video preload="metadata" autoPlay loop muted>
        <source src="/assets/video_2.mp4" type="video/mp4" />
      </video>
      <div className="intro-content">
        <NavLink to="/home">
          <SpinningLogo className={className} />
          <IonRow className="title-row-intro ">
            <IonText className="title-text-intro">
              HARDCORE
              <br />
              &thinsp;BUDDHIST
            </IonText>
          </IonRow>
        </NavLink>
      </div>
    </div>
  );
};
