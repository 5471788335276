import React, { useState } from "react";
import axios from "axios";
import { PageTitle } from "../Components";
import { API_URL } from "../config/constants";
import { validateEmail } from "../helpers/helpers";

export const SubscribeForm = (props) => {
  const { isIntroPage } = props;
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [error, setError] = useState("");

  const handleEmailChange = (event) => {
    const { value } = event.target;
    setEmail(value);
    setIsValidEmail(validateEmail(value));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isValidEmail) {
      setIsSubmitted(false);
      setError("Please enter a valid email address.");
      setEmail("");
      return;
    }
    try {
      await axios.post(`${API_URL}/subscribe`, {
        name: name,
        email: email,
      });

      setIsSubmitted(true);
      setName("");
      setEmail("");
      setError("");
      // Optionally, show success message or navigate to another page
    } catch (error) {
      setError("An error occurred while subscribing. Please try again later.");
      console.error("Error:", error);
    }
  };

  if (isIntroPage) {
    return null;
  }

  return (
    <div className="subscribe">
      {isSubmitted && (
        <p className="flex-center" style={{ color: "white" }}>
          Form submitted successfully!
        </p>
      )}
      {error && (
        <p className="flex-center" style={{ color: "white" }}>
          {error}
        </p>
      )}
      <div>
        <form onSubmit={handleSubmit} className="subscribe-form">
          <PageTitle pageTitle={"SUBSCRIBE TO NEWSLETTER"} />
          <input
            type="text"
            name="name"
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <input
            type="email"
            name="email"
            placeholder="Email"
            value={email}
            onChange={handleEmailChange}
          />
          <button type="submit" className="subscribe-btn shadow-img">
            <div className="subscribe-btn-text">SUBSCRIBE</div>
          </button>
        </form>
      </div>
    </div>
  );
};
